// Journal 5.3.2
// Bootswatch


// Variables

$web-font-path: "https://fonts.googleapis.com/css2?family=News+Cycle:wght@400;700&display=swap" !default;
@if $web-font-path {
  @import url($web-font-path);
}

// Navbar

.navbar {
  font-family: $headings-font-family;
  font-size: 18px;
  font-weight: $headings-font-weight;
}

.navbar-brand {
  padding-top: .5rem;
  font-size: inherit;
  font-weight: $headings-font-weight;
  text-transform: uppercase;
}

// Buttons

.btn {
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;

  // &-secondary,
  // &-warning {
  //   color: $white;
  // }
}

// Navs

.pagination {
  a:hover {
    text-decoration: none;
  }
}
